<template lang="pug">
include ../../../configs/template.pug
div.row.align-end
  div(v-if="!$route.params?.documentID").col-sm-12.col-md-6
    +select-validation('body.course', 'mappingCourses', 'course', 'nameLang', '["required"]')(
      @input="getInstitutionsList")
  div.col-sm-12.col-md-6
    +select-validation('body.city', 'institutionsCity', 'city', '""', '["required"]')(
      @input="getInstitutionsList")
  div(v-if="$route.params?.documentID").col-sm-12.col-md-6
    +date-picker-validation('body.date_meeting', 'meetingDate', 'dateMeetingObject', '["required", "minValue", "maxValue"]')(
      min="1900-01-01" max="2200-01-01")
  div.col-12
    +select-validation('body.institution', 'filteredInstitutionsList[0] || []', 'nameInstitution', '"institutionName"', '["required"]')(
      return-object
    )
  div.col-12.d-flex.justify-center.mt-3
    v-btn(@click="setCertificateStatementETI" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { sailorStatementETIAdding } from '@/mixins/validationRules'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  course: null,
  city: null,
  institution: null,
  date_meeting: null
})
export default {
  name: 'FormCreateEditStatementCertificateETI',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      institutionsCity: ['Чорноморськ', 'Одеса', 'Миколаїв', 'Херсон', 'Маріуполь', 'Ізмаїл'],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      filteredInstitutionsList: state => state.directory.filteredETI,
      mappingCourses: state => state.directory.courses,
      listInstitution: state => state.directory.institution
    }),
    dateMeetingObject () {
      return this.body.date_meeting ? new Date(this.body.date_meeting) : null
    }
  },
  validations () { return sailorStatementETIAdding(this) },
  mounted () {
    if (this.$route.params?.documentID) {
      delete this.body.city
      Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
      this.body.city = this.getDirectoryObject({ id: this.statement.institution, value: 'educationTraining' })?.address
      this.getInstitutionsList()
    }
  },
  methods: {
    ...mapActions(['setCertificateStatement', 'getFilteredETI', 'updateCertificateStatement']),

    getInstitutionsList () {
      if (!this.body.course || !this.body.city) return false
      const searchQueries = {
        course: this.body.course,
        city: this.body.city,
        arrayIndex: 0,
        nameLang: this.nameLang
      }
      this.getFilteredETI(searchQueries).then(() => {
        this.body.institution = this.filteredInstitutionsList[0][0]
      })
    },
    async setCertificateStatementETI () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: { ...this.body }
      }

      if (this.$route.params?.documentID) {
        delete data.body.city
        delete data.body.course
        data.body.institution = this.body.institution.ntz.id
      } else {
        data.body.sailor = this.$route.params.id
        data.body.institution = this.body.institution.ntz.id
      }
      const response = this.$route.params.documentID ? await this.updateCertificateStatement(data) : await this.setCertificateStatement(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.$route.params.documentID && (this.$parent.isViewAddSlot = false)
        this.$v.$reset()
        this.body = initBody()
      }
      this.isLoading = false
    }
  }
}
</script>
